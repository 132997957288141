// @filename: client.ts
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";
import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";
import { services } from "./services";
import EnvService from "./EnvService";

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();

const url = services.bff[EnvService.STAGE];

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url,
      headers: async () => {
        const token = await getAccessToken();
        return {
          Authorization: `Bearer ${token}`,
          "X-FLASH-AUTH": `Bearer ${token}`,
        };
      },
    }),
  ],
});
