interface ServicesProps {
  [key: string]: {
    development: string;
    staging: string;
    production: string;
  };
}

export const services = {
  bff: {
    development: "http://localhost:3000/trpc",
    staging: "https://hros-web-finance.private.flashapp.dev/trpc",
    production: "https://hros-web-finance.us.flashapp.services/trpc",
  },
} as ServicesProps;
